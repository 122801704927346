import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { IconButton, makeStyles, Button, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    display: 'flex',
    position: 'relative',
    width: '75%',
    height: '75%',
    padding: '20px 40px',
    flexDirection: 'column',
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
  close: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}));

export default function SimpleModal({ children, openPreview, closePreview }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handlePreviewFile = useCallback(
    async (action) => {
      if (openPreview) {
        setOpen(true);
      }
      if (action) {
        setOpen(false);
        closePreview(null);
      }
    },
    [openPreview, closePreview],
  );

  const closeModal = useCallback(
    async (action) => {
      handlePreviewFile(action);
    },
    [handlePreviewFile],
  );

  useEffect(() => {
    handlePreviewFile();
    return () => {};
  }, [openPreview, closePreview, children, handlePreviewFile]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      className={classes.modal}
    >
      <div className={classes.body} role="dialog" alt={t('Modal Window')} aria-modal="true">
        <IconButton className={classes.close} aria-label={t('Close')} onClick={() => closeModal(true)}>
          <CloseIcon fontSize="small" alt={t('close Modal Window')} />
        </IconButton>

        {children}

        <Box my={2} textAlign="right">
          <Button
            variant="outlined"
            color="primary"
            id="closeModal"
            className={classes.actionButton}
            onClick={() => closeModal(true)}
          >
            {t('Close')}
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
