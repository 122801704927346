import React from "react";
import { Box, Paper } from "@material-ui/core/";
import ImageGallery from "react-image-gallery";
import { Layout } from "../components/layout/Layout";
import "react-image-gallery/styles/css/image-gallery.css";
import axios from "axios";

// const regex = /"(https:\/\/lh3\.googleusercontent\.com\/[a-zA-Z0-9\-_]*)"/g
// function extractPhotos(content) {
//   const links = []
//   let match;
//   while ((match = regex.exec(content))) {
//     links.add(match[1]);
//   }
//   return Array.from(links);
// }
// async function getAlbum() {
//   const response = await axios.get(
//     `https://photos.app.goo.gl/LPW4iFZhsawoZA6d6`
//   );
//   return extractPhotos(response.data);
// }
// async function getAlbum(id) {
//   const response = await axios.get(`https://photos.app.goo.gl/${id}`)
//   return extractPhotos(response.data)
// }
// sapins: https://google-photos-album-demo2.glitch.me/4eXXxxG3rYwQVf948

const ImagesGallery = () => {
  // const [images, setImages] = React.useState(null);

  // React.useEffect(() => {
  //   let shouldCancel = false;

  //   const call = async () => {
  //     const response = await axios.get(
  //       "https://google-photos-album-demo2.glitch.me/7hn166fEhf9rBcY66"
  //     );
  //     if (!shouldCancel && response.data && response.data.length > 0) {
  //       setImages(
  //         response.data.map((url) => ({
  //           original: `${url}=w1024`,
  //           thumbnail: `${url}=w100`,
  //         }))
  //       );
  //     }
  //   };
  //   call();
  //   return () => (shouldCancel = true);
  // }, []);

  // setImages([{original: "/brasselephant.png", thumbnail: "/brasselephant.png"}])

  const images = [
    // { original: "/brasselephant.png", thumbnail: "/brasselephant.png" },
  ];
  for(let i = 1; i < 8; i ++) {
    images.push({ original: `/gallery/${i}.jpg`, thumbnail: `/gallery/${i}.jpg` })
  }

  return images ? <ImageGallery items={images} /> : null;
};

const HomePage = () => {
  return (
    <Layout>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Box
          style={
            {
              // maxWidth: "77%",
              // minHeight: "70%",
              // maxHeight: "70%",
            }
          }
        >
          <ImagesGallery />
        </Box>
      </div>
    </Layout>
  );
};

export default HomePage;
