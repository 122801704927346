import { createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    // primary: { main: "#2a3337" },
    // https://coolors.co/662d91-6baa75-84dd63-cbff4d-5f5449
    primary: { main: "#444" },
    secondary: { main: "#d3362f" },
    success: green,
  },
  typography: {
    fontFamily: [
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MUIDataTable: {
      paper: {
        paddingRight: '20px',
        paddingLeft: '20px',
      },
    },
    MUIDataTableBodyCell: {
      stackedCommon:{
        whiteSpace: "noWrap"
      },
      root: {
        '&:nth-child(1)': {
          width: "25px"
        },
        backgroundColor: "#f7f7f7",
      }
    },
    MUIDataTableHeadCell: {
      data: {
        whiteSpace: "noWrap"
      },
    }
  },
});

export default theme;