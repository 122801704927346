import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { FETCH_POSTS_QUERY } from "../../components/apollo/graphql";
import {Tooltip, Typography, IconButton} from "@material-ui/core";
import { Box, Button, Dialog } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

function DeleteButton({ postId, commentId, callback }) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const mutation = commentId ? DELETE_COMMENT_MUTATION : DELETE_POST_MUTATION;

  const [deletePostOrMutation] = useMutation(mutation, {
    update(proxy) {
      setConfirmOpen(false);      
      if (!commentId) {
        const data = proxy.readQuery({
          query: FETCH_POSTS_QUERY,
        });
        data.getPosts = data.getPosts.filter((p) => p.id !== postId);
        proxy.writeQuery({ query: FETCH_POSTS_QUERY, data });
      }
      if (callback) callback();      
    },
    variables: {
      postId,
      commentId,
    },
  });
  return (
    <>
    <Tooltip title="Delete">
      <IconButton aria-label="Delete" onClick={() => setConfirmOpen(true)}>
        <DeleteIcon />
      </IconButton>
      </Tooltip>
      <Dialog open={confirmOpen} fullWidth={true}>
        <Box p={4}>
          <Typography variant="h5" gutterBottom>
            Sure?
          </Typography>

          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              id="cancel"
              onClick={() => setConfirmOpen(false)}
              style={{ marginRight: "7px" }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" id="post" type="submit" onClick={deletePostOrMutation}>
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

const DELETE_POST_MUTATION = gql`
  mutation deletePost($postId: ID!) {
    deletePost(postId: $postId)
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($postId: ID!, $commentId: ID!) {
    deleteComment(postId: $postId, commentId: $commentId) {
      id
      comments {
        id
        username
        createdAt
        body
      }
      commentCount
    }
  }
`;

export default DeleteButton;
