import React, { Suspense } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";
import Routes from "./_routes";
import theme from "../components/theme/HridayaTheme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Backdrop blocking={true} />}>
        <Routes />
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
