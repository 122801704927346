// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './pages/_app';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom';

// import App from './pages/_app';
// // import App from './App';

// import './styles/main.bundle.css';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

import ReactDOM from "react-dom";
import ApolloProvider from "./components/apollo/ApolloProvider";
import "./styles/main.bundle.css";
import './i18n';

ReactDOM.render(ApolloProvider,document.getElementById("root")
);
