import React, { Suspense, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Box, Button, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Avatar from "@material-ui/core/Avatar";

const Teachers = () => {
  const [teacherData, setTeacherData] = useState();
  // const [data, setData] = useState();
  const [hasError, setErrors] = useState(false);

  async function fetchData() {
    const res = await fetch(
      "https://hridaya-yoga-fr.secure.retreat.guru/api/v1/teachers?limit=108&token=be1183a72bf4586ed9b6943fbae0c60c"
    );
    console.log(teacherData);
    res
      .json()
      .then((res) => setTeacherData(res))
      .catch((err) => setErrors(err));
  }

  useEffect(() => {
    fetchData();
    console.log("useEffect: ");
    console.log(teacherData);
  }, []);

  // https://www.robinwieruch.de/react-hooks-fetch-data

  const createRowFromRowData = (rowData) => {
    return {
      id: rowData[0],
      name: rowData[1],
      image: rowData[2],
      role: rowData[3],
    };
  };

  const columns = [
    { name: "id", label: "ID" },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        customBodyRender: (data, tableMeta) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: data,
              }}
            ></div>
          );
        },
      },
    },
    {
      name: "images",
      label: "Image",
      options: {
        filter: true,
        customBodyRender: (data, tableMeta) => {
          const row = createRowFromRowData(tableMeta.rowData);
          return <Avatar src={data.medium?.url} />;
        },
      },
    },
    {
      name: "self_url",
      label: "Profile",
      options: {
        filter: true,
        customBodyRender: (data, tableMeta) => {
          return (
            <Link to={data}>Profile</Link>
          );
        },
      },
    },
    { name: "type", label: "Role" },
  ];

  const options = {
    filterType: "checkbox",
    resizableColumns: false,
    selectableRows: false,
    rowsPerPage: 7,
    rowsPerPageOptions: [7,10,15,20],
  };

  return (
      <Box style={{ justifyContent: "center", display: "flex" }}>
        {/* <Button onClick={fetchData()}> Teachers </Button> */}

        <Box style={{ width: "100%" }}>
          <MUIDataTable
            title="Teachers"
            data={teacherData}
            columns={columns}
            options={options}
          />
        </Box>
        {/* <span>Has error: {JSON.stringify(hasError)}</span> */}
      </Box>
  );
};

export default Teachers;
