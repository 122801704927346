import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Switch, Route } from "react-router-dom";
import { Layout } from "../../components/layout/Layout";
import Payments from "./payments";
import Teachers from "./teachers";
import Invoice from "./invoice";
import { Box, Button, Paper } from "@material-ui/core";
import AuthRoute from "../../utils/AuthRoute";
import RetreatDrawer from "./menu";
import Iframe from "react-iframe";

const useStyles = makeStyles((theme) => ({
  iframe: {
    borderWidth: "0px",
    borderStyle: "inset",
    borderColor: "initial",
    borderImage: "initial",
  },
  button: {
    margin: "10px 10px 10px 10px",
  },
  link: {
    textDecoration: "none",
  },
}));

function Retreat() {
  const classes = useStyles();
  return (
    <Layout>
      <Box display="flex" justifyContent="center">
        {/* <RetreatDrawer/> */}
        {/* <Button
          className={classes.button}
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="outlined"
          color="primary"
        >
          <Link to="/retreat/payments" className={classes.link}>Payments</Link>
        </Button>
        <Button
          className={classes.button}
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="outlined"
          color="primary"
        >
          <Link to="/retreat/teachers" className={classes.link}>Teachers</Link>
        </Button> */}
      </Box>
      <Switch>
        <AuthRoute path="/retreat/payments" component={Payments} />
        <Route path="/retreat/invoice/:paymentId">
          <Invoice />
        </Route>
        <Route path="/retreat/teachers">
          <Teachers />
        </Route>
        <Route path="/retreat">
          <Iframe
            url="http://www.retreat.guru"
            width={window.innerWidth}
            height={window.innerHeight}
            // height={window.innerHeight / 2}
            id="hfr"
            display="initial"
            position="relative"
            className={classes.iframe}
          />
        </Route>
      </Switch>
    </Layout>
  );
}

export default Retreat;
