import React, { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import MUIDataTable from "mui-datatables";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const Payments = () => {
  const [paymentData, setPaymentData] = useState();
  const now = new Date();
  const weekAgo = new Date().setDate(now.getDate()-7);
  const [fromDate, setFromDate] = useState(new Date(weekAgo));
  const [toDate, setToDate] = useState(now);
  const [hasError, setErrors] = useState(false);

  async function fetchData() {
    const uri =
      "https://hridaya-yoga-fr.secure.retreat.guru/api/v1/payments?limit=108&min_date=" +
      fromDate.getFullYear() +
      "-" +
      (fromDate.getMonth() + 1) +
      "-" +
      fromDate.getDate() +
      "&max_date=" +
      toDate.getFullYear() +
      "-" +
      (toDate.getMonth() + 1) +
      "-" +
      toDate.getDate() +
      "&token=be1183a72bf4586ed9b6943fbae0c60c";
    console.log(uri);

    const res = await fetch(uri);
    res
      .json()
      .then((res) => setPaymentData(res))
      .catch((err) => setErrors(err));
  }

  useEffect(() => {
    fetchData();
    console.log(paymentData);
  }, [toDate, fromDate]);

  // https://www.robinwieruch.de/react-hooks-fetch-data

  const columns = [
    { name: "id", label: "ID" },
    // { name: "type", label: "Type" },
    {
      name: "trans_date",
      label: "Date",
      options: {
        filter: true,
        customBodyRender: (data, tableMeta) => {
          const date = new Date(data);
          return date.toDateString();
        },
      },
    },
    { name: "person_name", label: "Person Name" },
    {
      name: "program_name",
      label: "Program Name",
      options: {
        filter: true,
        customBodyRender: (data, tableMeta) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: data,
              }}
            ></div>
          );
        },
      },
    },
    { name: "status", label: "Payment status" },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: true,
        customBodyRender: (data, tableMeta) => {
          // console.log(tableMeta)
          return (
            <Link to={`/retreat/invoice/${data}`}>
              <PictureAsPdfIcon />
            </Link>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    resizableColumns: false,
    selectableRows: false,
    rowsPerPage: 7,
    rowsPerPageOptions: [7,10,15,20],
    // customToolbar: () => {return <h1>Toolbar</h1>},
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    console.log(date.getDate());
    console.log(date.getMonth() + 1);
    console.log(date.getFullYear());
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  return (
    <Box style={{
      backgroundImage: 'url("/ele.png")',
      position: "relative",
      zIndex: 1,
      height: window.innerHeight - 110,
      backgroundSize: "cover",
      backgroundColor: "rgba(255,255,255,0.7)",
      backgroundBlendMode: "overlay",
    }}>
      <Grid container display="grid" justify="space-around" style={{backgroundColor:"white"}}>
        <Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container display="grid" justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="fromDate"
                label="From"
                autoOk={true}
                value={fromDate}
                onChange={handleFromDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="toDate"
                label="To"
                autoOk={true}
                value={toDate}
                onChange={handleToDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              {/* <Box style={{ alignSelf: "center" }}>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  style={{ margin: "7px" }}
                  onClick={fetchData}
                >
                  Get payments
                </Button>
              </Box> */}
            </Grid>
          </MuiPickersUtilsProvider>
        </Box>
      </Grid>

      <Box style={{ width: "100%" }}>
        <MUIDataTable
          title="Payment List"
          data={paymentData}
          columns={columns}
          options={options}
        />
        {/* <span>Has error: {JSON.stringify(hasError)}</span> */}
      </Box>
    </Box>
  );
};

export default Payments;
