import React from "react";
import { useTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import GalleryIcon from '@material-ui/icons/PermMediaOutlined';
import AppsIcon from '@material-ui/icons/Apps';
import NewsIcon from '@material-ui/icons/ChatBubbleOutline';

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CentersMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ marginRight: "7px" }}
      >
        <AppsIcon style={{ marginRight: "7px" }} />
        {t("Showcase")}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem component={Link} to="/social">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={t("Mastodon")} />
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/gallery">
          <ListItemIcon>
            <GalleryIcon />
          </ListItemIcon>
          <ListItemText primary={t("Gallery")} />
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/social/poster">
          <ListItemIcon>
            <NewsIcon />
          </ListItemIcon>
          <ListItemText primary={t("Posts")} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
