import React from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TranslateIcon from "@material-ui/icons/Translate";
import ReactCountryFlag from "react-country-flag";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function TranslateMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    props.changeLanguage(lng);
    handleClose();
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ marginRight: "7px" }}
      >
        <TranslateIcon style={{ marginRight: "7px" }} />
        {t("Language")}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => changeLanguage("en")}>
          <ListItemIcon>
            <ReactCountryFlag
              countryCode="US"
              svg
              style={{
                width: "1em",
                height: "1em",
                align: "right",
              }}
              title="US"
            />
          </ListItemIcon>
          <ListItemText primary={t("English")} />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => changeLanguage("fr")}>
          <ListItemIcon>
            <ReactCountryFlag
              countryCode="FR"
              svg
              style={{
                width: "1em",
                height: "1em",
                align: "right",
              }}
              title="FR"
            />
          </ListItemIcon>
          <ListItemText primary={t("French")} />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => changeLanguage("hu")}>
          <ListItemIcon>
            <ReactCountryFlag
              countryCode="HU"
              svg
              style={{
                width: "1em",
                height: "1em",
                align: "right",
              }}
              title="HU"
            />
          </ListItemIcon>
          <ListItemText primary={t("Hungarian")} />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => changeLanguage("ro")}>
          <ListItemIcon>
            <ReactCountryFlag
              countryCode="RO"
              svg
              style={{
                width: "1em",
                height: "1em",
                align: "right",
              }}
              title="RO"
            />
          </ListItemIcon>
          <ListItemText primary={t("Romanian")} />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => changeLanguage("es")}>
          <ListItemIcon>
            <ReactCountryFlag
              countryCode="ES"
              svg
              style={{
                width: "1em",
                height: "1em",
                align: "right",
              }}
              title="ES"
            />
          </ListItemIcon>
          <ListItemText primary={t("Spanish")} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
