import React from "react";
import { useTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PaymentIcon from "@material-ui/icons/Payment";
import SpaIcon from "@material-ui/icons/Spa";
import TeacherIcon from "@material-ui/icons/ChildCare";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function RetreatMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ marginRight: "7px" }}
      >
        <SpaIcon style={{ marginRight: "7px" }} />
        {t("Retreat Guru")}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem component={Link} to="/retreat" onClick={handleClose}>
          <ListItemIcon>
            <SpaIcon />
          </ListItemIcon>
          <ListItemText primary={t("Retreat Guru")} />
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/retreat/payments" onClick={handleClose}>
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary={t("Payments")} />
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/retreat/teachers" onClick={handleClose}>
          <ListItemIcon>
            <TeacherIcon />
          </ListItemIcon>
          <ListItemText primary={t("Teachers")} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
