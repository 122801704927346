import React, { useContext } from "react";
import { Backdrop, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { Layout } from "../../components/layout/Layout";
import { Link } from "react-router-dom";
import PostCard from "../../components/poster/PostCard";
import { AuthContext } from "../../context/auth";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { FETCH_POSTS_QUERY } from "../../components/apollo/graphql";

const useStyles = makeStyles((theme) => ({
  heroButtons: {
    marginTop: theme.spacing(3),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Poster() {
  const { loading, data } = useQuery(FETCH_POSTS_QUERY);
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  const refresh = () => {
    console.log("refresh");
  };

  return (
    <Layout>
      <Box
        style={{
          backgroundImage: 'url("/ele.png")',
          position: "relative",
          zIndex: 1,
          height: window.innerHeight - 110,
          backgroundSize: "cover",
          backgroundColor: "rgba(255,255,255,0.7)",
          backgroundBlendMode: "overlay",
        }}
      >
        <Grid
          container
          spacing={2}
          justify="center"
          style={{ display: "grid", marginTop: "0px", opacity: 1, zIndex: 2 }}
        >
          {user && (
            <Link
              to={`/social/createpost`}
              style={{ color: "black", textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "7px" }}
              >
                Post new
              </Button>
            </Link>
          )}
        </Grid>
        {loading ? (
          <Backdrop className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Container className={classes.cardGrid} maxWidth="md">
            <Grid container spacing={4}>
              {data.getPosts.map((post) => (
                <Grid item key={post.id} xs={12} sm={6} md={4}>
                  {/* <Collapse in={checked}> */}
                  <PostCard post={post} refresh={refresh} />
                  {/* </Collapse> */}
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
      </Box>
    </Layout>
  );
}
