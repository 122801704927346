import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { AuthContext } from "../../context/auth";

export default function SignoutMenu() {
  const { logout } = useContext(AuthContext);
  const { t } = useTranslation();
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={logout}
        style={{ marginRight: "7px" }}
      >
        <Link
          to="/"
          style={{
            color: "white",
            textDecoration: "none",
            marginRight: "7px",
          }}
        >
          {t("Sign out")}
        </Link>
        <ExitIcon />
      </Button>
    </div>
  );
}
