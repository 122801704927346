import React from "react";
import Iframe from "react-iframe";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import { Layout } from "../components/layout/Layout";

const useStyles = makeStyles((theme) => ({
  iframe: {
    borderWidth: "0px",
    borderStyle: "inset",
    borderColor: "initial",
    borderImage: "initial",
  },
}));

const Mexico = () => {
  const classes = useStyles();
  return (
    <Layout>
      {/* <iframe allowfullscreen sandbox="allow-top-navigation allow-scripts" width="400" height="800" src="https://www.mastofeed.com/apiv2/feed?userurl=https%3A%2F%2Fhridaya.social%2Fusers%2Fzoltan&theme=light&size=100&header=false&replies=false&boosts=false"></iframe> */}

      <Box style={{ justifyContent: "center", display: "flex" }}>
        <Paper>
          <Iframe
            url="https://www.mastofeed.com/apiv2/feed?userurl=https%3A%2F%2Fhridaya.social%2Fusers%2Fzoltan&theme=light&size=100&header=false&replies=false&boosts=false"
            width="400"
            height={window.innerHeight}
            id="hfr"
            display="initial"
            position="relative"
            className={classes.iframe}
          />
        </Paper>
      </Box>
    </Layout>
  );
};

export default Mexico;
