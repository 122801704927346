import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Button } from "@material-ui/core";
import LightSwitchOffIcon from "@material-ui/icons/EmojiObjects";
import LightSwitchOnIcon from "@material-ui/icons/EmojiObjectsOutlined";
import { ThemeContext } from "../theme/ThemeContext";

export default function CentersMenu() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={toggleTheme}
        style={{ marginRight: "7px" }}
      >
        {theme === "light" ? <LightSwitchOnIcon /> : <LightSwitchOffIcon />}
        {theme !== "light" ? t("Light") : t("Dark")}
      </Button>
    </div>
  );
}
