import React from "react";
import { useTranslation } from "react-i18next";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Font,
  Page,
  Text,
  Image,
  Note,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    margin: 24,
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  note: {
    margin: 12,
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Times-Roman",
  },
  total: {
    borderTop: 0.3,
    borderTopColor: "grey",
    margin: 12,
    fontSize: 14,
    textAlign: "right",
    fontFamily: "Times-Roman",
  },
  address: {
    margin: 12,
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default function PDFInvoice(props) {
  const { data } = props;
  const paymentData = data[0];
  const PDFDocument = () => (
    <Document>
      <Page size="A4" style={styles.body}>
        <Text style={styles.header} fixed>
          ~ Hridaya France ~
        </Text>
        <Text style={styles.address}>
          {t("Hridaya France")}
          {"\n"}
          {t("Invoice p2")}
          {"\n"}
          {t("Invoice address 1")}
          {"\n"}
          {t("Invoice address 2")}
          {"\n"}
          {t("Invoice TVA data")}
          {"\n"}
        </Text>
        {/* <View style={{ color: "black", textAlign: "center", margin: 30 }}> */}
        <Text style={styles.title}>{t("Invoice")}</Text>
        {/* <Text style={{ color: "black", textAlign: "left", marginTop: 50 }}>{t("Address")}:</Text> */}
        <Text style={styles.text}>{paymentData?.program_name}</Text>
        <Text style={styles.total}>€{paymentData?.grand_total}</Text>
        <Text style={styles.note}>
          {t("Invoice p1")}
          {"\n"}
          {"\n"}
        </Text>
        <Note>{t("Note text")}</Note>        
        {/* <Image
          style={styles.image}
          src={paymentData?.merchant_cc_details?.image}
        /> */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
  const { t } = useTranslation();
  return (
    <>
      <PDFViewer width="100%" height="100%">
        <PDFDocument />
      </PDFViewer>
    </>
  );
}
