import React from "react";
import { useTranslation } from 'react-i18next';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TransitEnterexitIcon from "@material-ui/icons/TransitEnterexit";

export default function SigninMenu() {
  const { t } = useTranslation();

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        style={{ marginRight: "7px" }}
      >
        <TransitEnterexitIcon style={{ marginRight: "7px" }} />
        <Link to="/signin" style={{ color: "white", textDecoration: "none" }}>{t("Sign in")}</Link>
      </Button>
    </div>
  );
}
