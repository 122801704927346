import React, { useContext, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import { Layout } from "../../components/layout/Layout";
import { AuthContext } from "../../context/auth";
import {
  Box,
  Button,
  Typography,
  Collapse,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Badge from "@material-ui/core/Badge";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import DeleteButton from "../../components/poster/DeleteButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChatIcon from "@material-ui/icons/Chat";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import randomcolor from "randomcolor";

import {
  FETCH_POST_QUERY,
  LIKE_POST_MUTATION,
  SUBMIT_COMMENT_MUTATION,
} from "../../components/apollo/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 350,
  },
  media: {
    height: 0,
    paddingTop: "7%",
    // paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
    // backgroundColor: randomcolor(),
  },
}));

function ViewPost(props) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [expanded, setExpanded] = React.useState(true);
  const [commentExpanded, setCommentExpanded] = React.useState(false);
  const commentInputRef = useRef(null);

  const [comment, setComment] = useState("");

  const postId = props.match.params.postid;
  const { data, loading } = useQuery(FETCH_POST_QUERY, {
    variables: {
      postId,
    },
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandCommentClick = () => {
    setCommentExpanded(!commentExpanded);
  };

  function deletePostCallback() {
    props.history.push("/social/poster");
  }

  const [submitComment] = useMutation(SUBMIT_COMMENT_MUTATION, {
    update() {
      setComment("");
      commentInputRef.current.blur();
    },
    variables: {
      postId,
      body: comment,
    },
  });

  const [likePost] = useMutation(LIKE_POST_MUTATION, {
    variables: { postId: postId },
  });

  let postMarkup;
  if (loading) {
    postMarkup = <p>Loading post...</p>;
  } else {
    const {
      id,
      body,
      createdAt,
      username,
      comments,
      likes,
      likeCount,
      commentCount,
    } = data.getPost;

    postMarkup = (
      <>
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar
                aria-label="recipe"
                className={classes.avatar}
                style={{
                  backgroundColor:
                    user.username === username ? "red" : randomcolor(),
                }}
              >
                {username.charAt(0).toUpperCase()}
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title={username}
            subheader={moment(createdAt).fromNow()}
          />
          <CardMedia
            className={classes.media}
            // image="https://source.unsplash.com/random/?tree"
            image="/flower.jpg"
            title="View"
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              <Link
                to="/social/poster"
                style={{ color: "black", textDecoration: "none" }}
              >
                {body}
              </Link>
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Tooltip title="Like">
              <IconButton aria-label="Like" onClick={likePost}>
                <Badge badgeContent={likeCount} color="secondary">
                  {/* <FavoriteIcon onClick={likePost} /> */}
                  {user ? (
                    likes.find((like) => like.username === user.username) ? (
                      <FavoriteIcon color="secondary" />
                    ) : (
                      <FavoriteIcon />
                    )
                  ) : (
                    <FavoriteIcon as={Link} to="/login" />
                  )}
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Comment on post">
              <IconButton
                aria-label="Comment"
                onClick={handleExpandCommentClick}
              >
                <Badge badgeContent={commentCount} color="secondary">
                  <ChatIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            {user && user.username === username && (
              <DeleteButton postId={id} callback={deletePostCallback} />
            )}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
        </Card>
        {user && (
          <Collapse in={commentExpanded} timeout="auto" unmountOnExit>
            <Card style={{ marginTop: 10 }}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="recipe"
                    className={classes.avatar}
                    style={{ backgroundColor: "red" }}
                  >
                    {user.username.charAt(0).toUpperCase()}
                  </Avatar>
                }
                title="Post a comment"
                // subheader={moment().format()}
              />

              <CardContent>
                <form>
                  <div>
                    <TextField
                      id="outlined-basic"
                      label="Comment"
                      variant="outlined"
                      placeholder=""
                      multiline
                      rows={3}
                      type="text"
                      name="comment"
                      value={comment}
                      onChange={(event) => setComment(event.target.value)}
                      ref={commentInputRef}
                      style={{
                        marginBottom: "14px",
                        width: "100%",
                      }}
                    />
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={comment.trim() === ""}
                        onClick={submitComment}
                      >
                        Submit
                      </Button>
                    </Box>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Collapse>
        )}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {comments.map((comment) => (
            <Card style={{ marginTop: 10 }}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="recipe"
                    className={classes.avatar}
                    style={{
                      backgroundColor:
                        user.username === username ||
                        user.username === comment.username
                          ? "red"
                          : randomcolor(),
                    }}
                  >
                    {comment.username.charAt(0).toUpperCase()}
                  </Avatar>
                }
                action={
                  user &&
                  user.username === comment.username && (
                    <DeleteButton postId={id} commentId={comment.id} />
                  )
                }
                title={comment.username}
                subheader={moment(comment.createdAt).fromNow()}
              />

              <CardContent>
                <Typography paragraph>{comment.body}</Typography>
              </CardContent>
            </Card>
          ))}
        </Collapse>
      </>
    );
  }
  return (
    <Layout>
      <Grid
        container
        spacing={2}
        justify="center"
        style={{ display: "grid", marginTop: "0px" }}
      >
        <Link
          to={`/social/poster/postid`}
          style={{
            color: "black",
            textDecoration: "none",
            textAlign: "center",
          }}
        >
          <Button variant="contained" color="primary" style={{ margin: "7px" }}>
            Posts
          </Button>
        </Link>
        {postMarkup}
      </Grid>
    </Layout>
  );
}

export default ViewPost;
