import "./Header.css";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theme/ThemeContext";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Box, Container, Link as OLink } from "@material-ui/core";
import { Link } from "react-router-dom";
import line from "./separator.png";
import styled, { keyframes } from "styled-components";
import { white } from "material-ui/styles/colors";
import randomcolor from "randomcolor";
import CentersMenu from "./CentersMenu";
import ShowcaseMenu from "./ShowcaseMenu";
import RetreatMenu from "./RetreatMenu";
import ThemeMenu from "./ThemeMenu";
import TranslateMenu from "./TranslateMenu";
import OneMenu from "./OneMenu";
import SigninMenu from "./SigninMenu";
import SignoutMenu from "./SignoutMenu";
import { AuthContext } from "../../context/auth";

const pulse = keyframes`
0% {
  fill:red;
  stroke-width:7px
}
50% {
  fill:white;
  stroke-width:3px
}
100%{
  fill:red;
  stroke-width:7px
}
`;

const StyledCircle = styled.svg`
  fill: red;
`;

const StyledInnerCircle = styled.circle`
  animation: ${pulse} infinite 49s linear;
  fill: blue;
`;

const circle = (
  <StyledCircle height="70" width="70">
    <StyledInnerCircle
      cx="35"
      cy="35"
      r="30"
      stroke="white"
      stroke-width="3"
      fill={randomcolor()}
    />
  </StyledCircle>
);

const useStyles = makeStyles((theme) => ({
  center: {
    fontSize: "normal",
  },
  root: {
    overflow: "auto",
  },
  link: {
    color: white,
    textDecoration: "none",
    textTransform: "none",
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    // marginLeft: "100px",
    marginLeft: "10px",
  },
  toolBar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.3),
  },
  logoLine: {
    height: "3em",
    marginRight: "1.5em",
  },
  spacer: {
    marginRight: theme.spacing(1),
  },
  strong: {
    fontWeight: "bold",
  },
  logo: {
    position: "fixed",
    zIndex: "19999",
    left: "10px",
    top: "10px",
  },
}));

export default function MenuAppBar() {
  const classes = useStyles();
  const { user, logout } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const { theme, toggleTheme } = useContext(ThemeContext);

  // const path = window.location.pathname;
  // const pathStr = path === '/' ? 'home' : path.substr(1);
  // const [activeItem, setActiveItem] = useState(pathStr);

  return (
    <>
      <div className={classes.root}>
        <AppBar className={`${theme}Theme`} position="static">
          <Toolbar className={classes.toolBar}>
            <Box display="inline-flex" style={{ flexGrow: 1 }}>
              <Box display="flex" width="100%">
                <Link to="/">
                  {/* <img src={"/hridaya.png"} alt="" width="30%" /> */}
                  {/* <img src={"/heart.png"} alt="Hridaya" width="63px" style={{padding: "7px"}}/> */}
                  {/* <img src={"/heartcenter.png"} alt="Hridaya" width="63px" style={{padding: "7px"}}/> */}
                  <img
                    src={"/108.png"}
                    alt="Hridaya"
                    width={window.innerWidth > 800 ? "73px" : "49px"}
                    style={{ padding: "7px" }}
                  />
                  {/* <img src={"/universe.png"} alt="Hridaya" width="63px" style={{padding: "7px"}}/> */}
                  {/* {circle} */}
                </Link>
                <Typography
                  variant={window.innerWidth > 800 ? "h6" : "h6"}
                  className={classes.title}
                  style={window.innerWidth > 800 ? { marginTop: "21px", textTransform: "uppercase", color: "whitesmoke"} : { fontSize: "normal", marginTop: "14px", textTransform: "uppercase", color: "whitesmoke"}}
                >
                  <OLink
                    href="https://www.wisdomlib.org/definition/avyakta"
                    target="_blank"
                    style={
                      theme === "light"
                        ? {
                            textDecoration: "none",
                            color: "#5F5449",
                          }
                        : {
                            textDecoration: "none",
                            color: "whitesmoke"
                          }
                    }
                  >
                    {t("avyakta")}
                  </OLink>
                  {user ? ` - ${user.username}` : ""}
                </Typography>
              </Box>
              {window.innerWidth > 800 ? (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ marginTop: "21px", whiteSpace: "nowrap" }}
                >
                  {/* <CentersMenu /> */}
                  {user ? <ShowcaseMenu /> : ""}
                  {user ? <RetreatMenu /> : ""}
                  <ThemeMenu />
                  <TranslateMenu changeLanguage={changeLanguage} />
                  {!user ? <SigninMenu /> : <SignoutMenu />}
                </Box>
              ) : (
                <OneMenu changeLanguage={changeLanguage}/>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}
