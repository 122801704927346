import React from "react";
import { ThemeContextProvider } from "../theme/ThemeContext";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  logo: {
    position: "fixed",
    zIndex: "19999",
    left: "10px",
    top: "10px",
  },
}));

export const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeContextProvider>
      {/* <Box className={classes.logo}>
        <Link to="/">
          <img src={"/hridaya.png"} alt="" width="30%" />
        </Link>
      </Box> */}
      <Header />
      {children}{" "}
    </ThemeContextProvider>
  );
};
