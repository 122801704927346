import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Badge from "@material-ui/core/Badge";
import { CardContent, Tooltip } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChatIcon from "@material-ui/icons/Chat";
import moment from "moment";
import { AuthContext } from "../../context/auth";
import { useMutation } from "@apollo/react-hooks";
import randomcolor from "randomcolor";
import { useHistory } from "react-router-dom";
import { LIKE_POST_MUTATION } from "../../components/apollo/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "11%",
    // paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
    // backgroundColor: randomcolor(),
  },
}));

export default function PostCard({
  post: { body, createdAt, id, username, likeCount, commentCount, likes },
  refresh,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { user } = useContext(AuthContext);
  const [liked, setLiked] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (user && likes.find((like) => like.username === user.username)) {
      setLiked(true);
    } else setLiked(false);
  }, [user, likes]);

  const [likePost] = useMutation(LIKE_POST_MUTATION, {
    variables: { postId: id },
  });

  return (
    <Card className={classes.root}>
      <Link
        to={`/social/viewpost/${id}`}
        style={{ color: "black", textDecoration: "none" }}
      >
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              // style={{ backgroundColor: randomcolor() }}
              style={{ backgroundColor: "purple" }}
            >
              {username.charAt(0).toUpperCase()}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={username}
          subheader={moment(createdAt).fromNow()}
        />
        <CardMedia
          className={classes.media}
          // image="https://source.unsplash.com/random/?tree"
          // image="/flower.jpg"
          image="/ele.png"
          title="View"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <Link
              to={`/social/viewpost/${id}`}
              style={{ color: "black", textDecoration: "none" }}
            >
              {body}
            </Link>
          </Typography>
        </CardContent>
      </Link>
      <CardActions disableSpacing>
        <Tooltip title="Like">
          <IconButton aria-label="Like" onClick={likePost}>
            <Badge badgeContent={likeCount} color="secondary">
              {/* <FavoriteIcon onClick={likePost} /> */}
              {user ? (
                likes.find((like) => like.username === user.username) ? (
                  <FavoriteIcon color="secondary" />
                ) : (
                  <FavoriteIcon />
                )
              ) : (
                <FavoriteIcon as={Link} to="/login" />
              )}
            </Badge>
          </IconButton>
        </Tooltip>
        <Link to={`/social/viewpost/${id}`}>
          <Tooltip title="Comment">
            <IconButton aria-label="Comment">
              <Badge badgeContent={commentCount} color="secondary">
                <ChatIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        </Link>
        {/* {user && user.username === username && (
          <DeleteButton postId={id} callback={deletePostCallback} />
        )} */}
        {/* <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton> */}
      </CardActions>
      {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Tantra</Typography>
          <Typography paragraph>1.</Typography>
          <Typography paragraph>2.</Typography>
          <Typography paragraph>3.</Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
}
