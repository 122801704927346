import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import SimpleModal from "../../components/common/SimpleModal";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PDFInvoice from "./PDFInvoice";
// import { useForm } from "../../utils/hooks";
import ReactJson from "react-json-view"

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Payments = () => {
  const { paymentId } = useParams();
  console.log("Payment Id: " + paymentId);
  const [paymentData, setPaymentData] = useState();
  const [hasError, setErrors] = useState(false);
  const { t } = useTranslation();

  async function fetchData() {
    const uri =
      "https://hridaya-yoga-fr.secure.retreat.guru/api/v1/payments?id=" +
      paymentId +
      "&token=be1183a72bf4586ed9b6943fbae0c60c";
    console.log(uri);

    const res = await fetch(uri);
    res
      .json()
      .then((res) => setPaymentData(res))
      .catch((err) => setErrors(err));
  }

  useEffect(() => {
    fetchData();
    console.log(paymentData);
  }, []);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // https://www.robinwieruch.de/react-hooks-fetch-data

  return (
    <>
      <Box
        display="flex"
        style={{
          backgroundImage: 'url("/ele.png")',
          position: "relative",
          zIndex: 1,
          height: window.innerHeight - 110,
          backgroundSize: "cover",
          backgroundColor: "rgba(255,255,255,0.7)",
          backgroundBlendMode: "overlay",
        }}
      >
        <Grid container>
          <Paper style={{ margin: "2rem", padding: "2rem", width: "100%" }}>
            <Link
              to={`/retreat/payments`}
              style={{ color: "black", textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "7px" }}
              >
                Back to payments
              </Button>
            </Link>
            <Typography variant="h4" style={{ textAlign: "center" }}>{t("Invoice")}</Typography>
            <Divider style={{ margin: "2rem" }} />
            <Typography>
              <b>{t("Address")}:</b>
            </Typography>
            <Typography>2370 Chemin de Longeval</Typography>
            <Typography>69870 St-Just-d'Avray, France</Typography>
            <Divider style={{ margin: "2rem" }} />
            <Typography><b>Name:</b> {paymentData && paymentData[0]?.person_name}</Typography>
            <Typography><b>Link:</b> {paymentData && paymentData[0]?.self_url}</Typography>
            {/* <Typography>{paymentData?.self_url}</Typography> */}
            <Divider style={{ margin: "2rem" }} />
            <Typography> </Typography>
            <ReactJson src={paymentData && paymentData[0]} />
            {/* {JSON.stringify(paymentData)} */}
            <Divider style={{ margin: "2rem" }} />
            <Button
              style={{ flex: 1 }}
              variant="contained"
              color="primary"
              onClick={openDialog}
            >
              View PDF
            </Button>
          </Paper>
        </Grid>
        <SimpleModal openPreview={isDialogOpen} closePreview={closeDialog}>
          <PDFInvoice data={paymentData} />
        </SimpleModal>
      </Box>
    </>
  );
};

export default Payments;
