import React from "react";
import { Box, TextField, Button, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { Layout } from "../../components/layout/Layout";
import { useForm } from "../../utils/hooks";
import {
  FETCH_POSTS_QUERY,
  CREATE_POST_MUTATION,
} from "../../components/apollo/graphql";

export default function CreatePost({ history }) {
  const { values, onChange, onSubmit } = useForm(createPostCallback, {
    body: "",
  });

  const [createPost, { error }] = useMutation(CREATE_POST_MUTATION, {
    variables: values,
    update(proxy, result) {
      const data = proxy.readQuery({
        query: FETCH_POSTS_QUERY,
      });
      data.getPosts = [result.data.createPost, ...data.getPosts];
      proxy.writeQuery({ query: FETCH_POSTS_QUERY, data });
      values.body = "";
      history.push(`/social/poster`);
    },
  });

  function createPostCallback() {
    createPost();
  }

  return (
    <Layout>
      <Box p={4}>
        <Typography variant="h5" gutterBottom>
          Create a post
        </Typography>
        <form onSubmit={onSubmit}>
          <TextField
            id="outlined-basic"
            label="Message"
            variant="outlined"
            placeholder=""
            name="body"
            multiline
            rows={7}
            onChange={onChange}
            value={values.body}
            error={error ? true : false}
            style={{
              marginBottom: "14px",
              width: "100%",
            }}
          />

          {error && (
            <div className="ui error message" style={{ marginBottom: 20 }}>
              <ul className="list">
                <li>{error.graphQLErrors[0].message}</li>
              </ul>
            </div>
          )}

          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              id="cancel"
              onClick={() => history.push("/social/poster")}
              style={{ marginRight: "7px" }}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" id="post" type="submit">
              Post
            </Button>
          </Box>
        </form>
      </Box>
    </Layout>
  );
}
