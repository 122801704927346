import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthProvider } from "../context/auth";
import AuthRoute from "../utils/AuthRoute";
import Mexico from "./mexico";
import France from "./france";
import Netherlands from "./netherlands";
import Retreat from "./retreat/retreat";
import Poster from "./social/poster";
import ViewPost from "./social/viewpost";
import CreatePost from "./social/createpost";
import Social from "./social";
import Gallery from "./gallery";
import Home from "./home";
import SignIn from "./admin/signin";
import SignUp from "./admin/signup";

const Routes = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/mexico">
            <Mexico />
          </Route>
          <Route path="/france">
            <France />
          </Route>
          <Route path="/netherlands">
            <Netherlands />
          </Route>
          <Route exact path="/social">
            <Social />
          </Route>
          <Route path="/signin">
            <SignIn />
          </Route>
          <AuthRoute path="/retreat" component={Retreat} />
          <AuthRoute path="/social/poster" component={Poster} />
          <AuthRoute path="/social/viewpost/:postid" component={ViewPost} />
          <AuthRoute path="/social/createpost" component={CreatePost} />
          <AuthRoute path="/gallery" component={Gallery} />
          <AuthRoute path="/signup" component={SignUp} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Routes;
