import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Iframe from 'react-iframe'

import {Layout} from '../components/layout/Layout';

const useStyles = makeStyles((theme) => ({
  iframe: {
    borderWidth: "0px",
    borderStyle: "inset",
    borderColor: "initial",
    borderImage: "initial",
  },
}));

const France = () => {
  const classes = useStyles();
  return (
    <Layout>
     <Iframe url="http://www.hridaya-yoga.fr"
        width={window.innerWidth}
        height={window.innerHeight}
        // height={window.innerHeight / 2}
        id="hfr"
        display="initial"
        position="relative"
        className={classes.iframe}/>

    </Layout>
  )
}

export default France;