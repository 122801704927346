import React, { Suspense, useContext, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { Layout } from "../components/layout/Layout";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import Avatar from "@material-ui/core/Avatar";
import { AuthContext } from "../context/auth";

const letter = () => (
  <Box>
    <Box
      style={{
        padding: "7%",
        backgroundImage: 'url("/ele.png")',
        backgroundSize: "cover",
      }}
      display="flex"
      justifyContent="center"
    >
      {/* <Paper style={{ padding: "7%", width: "91%" }}>
        <Typography variant="h5">Dear Karma Yogis,</Typography>
        <Typography variant="h7" align="justify">
          <p>
            At the moment the plan to develop the Hridaya Yoga website is to
            combine/merge two website structures into one using Wordpress
            technology by the end of this year by outsourcing this task to an
            agency.
          </p>
          <p>
            However the ultimate vision for the online presence of Hridaya could
            be an interface both on mobile and in a browser for all the Karma
            Yogis through which they can interact with the world of Hridaya,
            share knowledge, ideas, where they help each other create a new
            world acting selflessly from the heart by giving, contributing and
            sharing. The spiritual seekers would have a home there, a personal
            page, where they can create their own world, add their own workshops
            (which is e.g. showing up on a common/global calendar), add books to
            and manage the library, create content (music, vegan recipes,
            tapas...) which is not mixed up with other social site's noise. They
            could search for programs, services and creations made responsibly
            by the members and it would be possible to purchase these also. It
            would gradually integrate the functionality currently dispersed in
            retreat.guru, google sheets and minds and ultimately it could act as
            a real alternative to retreat.guru and could be open source, with
            opportunity for everybody to contribute.
          </p>
          <p>
            In the future there is a high probability there will be more than
            two Hridaya schools, maybe one in every country where the present
            Hridaya teachers from Mexico and France will go home from Hridaya
            and create a new Hridaya school/ashram/home. These schools will
            share the same core values, working in federation but where every
            school has its own local resources to manage. These schools could be
            built based on the knowledge which we would combine on a common
            platform and it would be possible to add a new school by the press
            of a button to the global Hridaya site. There could be different
            types of schools also. This platform has to spring from a new base
            without all the karma attached to the present solution. There is an
            absolute truth and ultimately there is only one system. It would be
            great if retreat.guru would be open to work together on this vision.{" "}
          </p>
          <p>
            It's possible to reach that point in a couple of years and the base
            for that is to start to build what we need to build now with
            technologies which allow us to reach this vision. This would be the
            perfect moment to choose wisely, without limiting our minds and we
            all (as Hridaya and as Karma Yogis) would grow immensely in
            knowledge, truth, interconnectedness, abundance and love if we would
            embark on this journey.
          </p>
          For consideration while we let our minds run free:
          <ul>
            <li>
              there is no urgency for the Wordpress site, since a static site
              (if the wireframe and design is ready) would not take long to
              create and we have the knowledge to make this happen inside
              Hridaya
            </li>
            <li>
              with what technologies would the agency be able to build the new
              site, would they be able to build it using React, Vue or Angular
              (or any of the top three frameworks)? For the decision makers:
              https://existek.com/blog/top-front-end-frameworks-2020/
            </li>
            <li>
              the work which is done until now on the new website is invaluable
              and wouldn't be lost whichever technology the next solution will
              be made with.
            </li>
          </ul>
          <p>Without judgement, attachment, resistance, identification,</p>
          <p>With curiosity and love,</p>
        </Typography>
      </Paper> */}
    </Box>
  </Box>
);
const Home = () => {
  const { user } = useContext(AuthContext);

  return (
    <Layout>
      {window.innerWidth > 375 ? (
        <Box
          style={{
            backgroundImage: 'url("/ele.png")',
            backgroundSize: "cover",
            height: window.innerHeight,
          }}
        >
          {user?.username === "carol" ? letter : ""}
        </Box>
      ) : (
        <Box
          style={{
            backgroundImage: 'url("/brasselephant.png")',
            backgroundSize: "cover",
            height: window.innerHeight,
            marginLeft: "-343px",
          }}
        >
          {user?.username === "carol" ? letter : ""}
        </Box>
      )}
    </Layout>
  );
};

export default Home;
