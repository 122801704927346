import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ReactCountryFlag from "react-country-flag";
import CentersIcon from '@material-ui/icons/FilterCenterFocus';

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CentersMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ marginRight: "7px" }} 
      >
        <CentersIcon style={{ marginRight: "7px" }}/>
        {t("Centers")}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem component={Link} to="/mexico">
          <ListItemIcon>
          <ReactCountryFlag 
                countryCode="MX"
                svg
                style={{
                  width: "1em",
                  height: "1em",
                  align: "right",
                }}
                title="MX"
              />
          </ListItemIcon>
          <ListItemText primary={t("Mexico")} />
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/france">
          <ListItemIcon>
          <ReactCountryFlag
                countryCode="FR"
                svg
                style={{
                  width: "1em",
                  height: "1em",
                }}
                title="FR"
              />
          </ListItemIcon>
          <ListItemText primary={t("France")} />
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/netherlands">
          <ListItemIcon>
          <ReactCountryFlag
                countryCode="NL"
                svg
                style={{
                  width: "1em",
                  height: "1em",
                }}
                title="NL"
              />
          </ListItemIcon>
          <ListItemText primary={t("Netherlands")}/>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
